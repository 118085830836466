import { useMemo } from 'react';
import { Alert, AlertIcon, Box, Button, Input, VStack } from '@chakra-ui/react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { GroupController, Radio } from 'frontend-components';
import { useStepId, useStore, useSubmitCustomStepForm } from 'frontend-common';

const payment_legal_tender =
  'Do you want to remit or receive payment in legal tender?';
const us_bank_account = 'Is your bank account based in the United States?';
const bank_name = 'Name of the nominating banking institution';
const bank_address = 'Address of the nominated banking institution';
const main_currency = 'Main Currency';
const us_domestic_account_name = 'US domestic account name';
const aba_routing_number = 'US domestic routing/ABA number';
const us_domestic_account_number = 'US domestic account number';
const us_domestic_reference_field = 'US domestic reference field';
const us_international_account_name = 'US international account name';
const us_internationa_swift_bic = 'US international SWIFT / BIC';
const us_international_account_number = 'US international account number';
const us_intenational_reference_field = 'US international reference field';
const account_name = 'Account Name';
const bank_iban = 'IBAN';
const bank_swift = 'SWIFT / BIC';

const validationSchema = Yup.object({
  [payment_legal_tender]: Yup.string().oneOf(['Yes', 'No']).required(),
  [us_bank_account]: Yup.string()
    .oneOf(['Yes', 'No'])
    .when(payment_legal_tender, {
      is: (val: string) => val === 'Yes',
      then: (schema) => schema.required(),
      otherwise: (schema) =>
        schema
          .optional()
          .nullable()
          .default(null)
          .transform(() => null),
    }),
  [bank_name]: Yup.string().when(payment_legal_tender, {
    is: (val: string) => val === 'Yes',
    then: (schema) => schema.required(),
    otherwise: (schema) =>
      schema
        .optional()
        .nullable()
        .default(null)
        .transform(() => null),
  }),
  [bank_address]: Yup.string().when(payment_legal_tender, {
    is: (val: string) => val === 'Yes',
    then: (schema) => schema.required(),
    otherwise: (schema) =>
      schema
        .optional()
        .nullable()
        .default(null)
        .transform(() => null),
  }),
  [main_currency]: Yup.string().when(payment_legal_tender, {
    is: (val: string) => val === 'Yes',
    then: (schema) => schema.required(),
    otherwise: (schema) =>
      schema
        .optional()
        .nullable()
        .default(null)
        .transform(() => null),
  }),
  [us_domestic_account_name]: Yup.string().when(us_bank_account, {
    is: (val: string) => val === 'Yes',
    then: (schema) => schema.required(),
    otherwise: (schema) =>
      schema
        .optional()
        .nullable()
        .default(null)
        .transform(() => null),
  }),
  [aba_routing_number]: Yup.string().when(us_bank_account, {
    is: (val: string) => val === 'Yes',
    then: (schema) => schema.required(),
    otherwise: (schema) =>
      schema
        .optional()
        .nullable()
        .default(null)
        .transform(() => null),
  }),
  [us_domestic_account_number]: Yup.string().when(us_bank_account, {
    is: (val: string) => val === 'Yes',
    then: (schema) => schema.required(),
    otherwise: (schema) =>
      schema
        .optional()
        .nullable()
        .default(null)
        .transform(() => null),
  }),
  [us_domestic_reference_field]: Yup.string().when(us_bank_account, {
    is: (val: string) => val === 'Yes',
    then: (schema) => schema.optional(),
    otherwise: (schema) =>
      schema
        .optional()
        .nullable()
        .default(null)
        .transform(() => null),
  }),
  [us_international_account_name]: Yup.string().when(us_bank_account, {
    is: (val: string) => val === 'Yes',
    then: (schema) => schema.required(),
    otherwise: (schema) =>
      schema
        .optional()
        .nullable()
        .default(null)
        .transform(() => null),
  }),
  [us_internationa_swift_bic]: Yup.string().when(us_bank_account, {
    is: (val: string) => val === 'Yes',
    then: (schema) => schema.required(),
    otherwise: (schema) =>
      schema
        .optional()
        .nullable()
        .default(null)
        .transform(() => null),
  }),
  [us_international_account_number]: Yup.string().when(us_bank_account, {
    is: (val: string) => val === 'Yes',
    then: (schema) => schema.required(),
    otherwise: (schema) =>
      schema
        .optional()
        .nullable()
        .default(null)
        .transform(() => null),
  }),
  [us_intenational_reference_field]: Yup.string().when(us_bank_account, {
    is: (val: string) => val === 'Yes',
    then: (schema) => schema.optional(),
    otherwise: (schema) =>
      schema
        .optional()
        .nullable()
        .default(null)
        .transform(() => null),
  }),
  [bank_iban]: Yup.string().when(us_bank_account, {
    is: (val: string) => val === 'No',
    then: (schema) => schema.min(15).max(34).required(),
    otherwise: (schema) =>
      schema
        .optional()
        .nullable()
        .default(null)
        .transform(() => null),
  }),
  [bank_swift]: Yup.string().when(us_bank_account, {
    is: (val: string) => val === 'No',
    then: (schema) => schema.min(8).max(12).required(),
    otherwise: (schema) =>
      schema
        .optional()
        .nullable()
        .default(null)
        .transform(() => null),
  }),
  [account_name]: Yup.string().when(us_bank_account, {
    is: (val: string) => val === 'No',
    then: (schema) => schema.required(),
    otherwise: (schema) =>
      schema
        .optional()
        .nullable()
        .default(null)
        .transform(() => null),
  }),
});

export const NominatedBankingAccount = () => {
  const stepId = useStepId();

  const { t } = useTranslation();
  const { submitCustomStepForm } = useSubmitCustomStepForm();
  const { metadata } = useStore();

  const defaultValues = useMemo(() => {
    return {
      [payment_legal_tender]: metadata?.[payment_legal_tender] || '',
      [us_bank_account]: metadata?.[us_bank_account] || '',
      [bank_name]: metadata?.[bank_name] || '',
      [bank_address]: metadata?.[bank_address] || '',
      [main_currency]: metadata?.[main_currency] || '',
      [us_domestic_account_name]: metadata?.[us_domestic_account_name] || '',
      [aba_routing_number]: metadata?.[aba_routing_number] || '',
      [us_domestic_reference_field]:
        metadata?.[us_domestic_reference_field] || '',
      [us_international_account_name]:
        metadata?.[us_international_account_name] || '',
      [us_internationa_swift_bic]: metadata?.[us_internationa_swift_bic] || '',
      [us_international_account_number]:
        metadata?.[us_international_account_number] || '',
      [us_intenational_reference_field]:
        metadata?.[us_intenational_reference_field] || '',
      [bank_iban]: metadata?.[bank_iban] || '',
      [bank_swift]: metadata?.[bank_swift] || '',
      [account_name]: metadata?.[account_name] || '',
    };
  }, [metadata]);

  const methods = useForm<any>({
    mode: 'all',
    criteriaMode: 'all',
    // @TODO - OPS-9 - Replace Yup by Zod
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    control,
    setValue,
    watch,
    resetField,
    formState: { isValid, isSubmitting },
  } = methods;

  const onSubmit: SubmitHandler<any> = async (formData) => {
    submitCustomStepForm({ caseMetadata: formData });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <VStack spacing="6" alignItems="start">
        <GroupController
          name={payment_legal_tender}
          label={t(`steps.${stepId}.${payment_legal_tender}.label`)}
          isRequired={true}
          control={control}
          render={(f) => (
            <Radio
              stepId={stepId}
              name={payment_legal_tender}
              onChange={(value: string) => {
                resetField(us_bank_account);
                resetField(bank_name);
                resetField(bank_address);
                resetField(main_currency);
                setValue(payment_legal_tender, value ?? '', {
                  shouldDirty: true,
                  shouldValidate: true,
                });
              }}
              options={['Yes', 'No']}
              defaultValue={f.value}
            />
          )}
        />

        {watch(payment_legal_tender) === 'Yes' && (
          <>
            <Alert status="info" mt="5">
              <AlertIcon />
              {t(`steps.${stepId}.information`)}
            </Alert>
            <GroupController
              name={us_bank_account}
              label={t(`steps.${stepId}.${us_bank_account}.label`)}
              isRequired={true}
              control={control}
              render={(f) => (
                <Radio
                  stepId={stepId}
                  name={us_bank_account}
                  onChange={(value: string) => {
                    resetField(us_domestic_account_name);
                    resetField(aba_routing_number);
                    resetField(us_domestic_account_number);
                    resetField(us_domestic_reference_field);
                    resetField(us_international_account_name);
                    resetField(us_internationa_swift_bic);
                    resetField(us_international_account_number);
                    resetField(us_intenational_reference_field);
                    resetField(account_name);
                    resetField(bank_iban);
                    resetField(bank_swift);
                    setValue(us_bank_account, value ?? '', {
                      shouldDirty: true,
                      shouldValidate: true,
                    });
                  }}
                  options={['Yes', 'No']}
                  defaultValue={f.value}
                />
              )}
            />
            <GroupController
              name={bank_name}
              label={t(`steps.${stepId}.${bank_name}.label`)}
              isRequired={true}
              control={control}
              render={(f) => {
                return <Input type="text" maxW="400px" {...f} />;
              }}
            />
            <GroupController
              name={bank_address}
              label={t(`steps.${stepId}.${bank_address}.label`)}
              isRequired={true}
              control={control}
              render={(f) => {
                return <Input type="text" maxW="400px" {...f} />;
              }}
            />
            <GroupController
              name={main_currency}
              label={t(`steps.${stepId}.${main_currency}.label`)}
              isRequired={true}
              control={control}
              render={(f) => {
                return <Input type="text" maxW="400px" {...f} />;
              }}
            />
            {watch(us_bank_account) === 'No' && (
              <>
                <GroupController
                  name={account_name}
                  label={t(`steps.${stepId}.${account_name}.label`)}
                  isRequired={true}
                  control={control}
                  render={(f) => {
                    return <Input type="text" maxW="400px" {...f} />;
                  }}
                />
                <GroupController
                  name={bank_iban}
                  label={t(`steps.${stepId}.${bank_iban}.label`)}
                  isRequired={true}
                  control={control}
                  render={(f) => {
                    return <Input type="text" maxW="400px" {...f} />;
                  }}
                />
                <GroupController
                  name={bank_swift}
                  label={t(`steps.${stepId}.${bank_swift}.label`)}
                  isRequired={true}
                  control={control}
                  render={(f) => {
                    return <Input type="text" maxW="400px" {...f} />;
                  }}
                />
              </>
            )}
            {watch(us_bank_account) === 'Yes' && (
              <>
                <GroupController
                  name={us_domestic_account_name}
                  label={t(`steps.${stepId}.${us_domestic_account_name}.label`)}
                  helper={t(
                    `steps.${stepId}.${us_domestic_account_name}.helper`,
                  )}
                  isRequired={true}
                  control={control}
                  render={(f) => {
                    return <Input type="text" maxW="400px" {...f} />;
                  }}
                />
                <GroupController
                  name={aba_routing_number}
                  label={t(`steps.${stepId}.${aba_routing_number}.label`)}
                  helper={t(`steps.${stepId}.${aba_routing_number}.helper`)}
                  isRequired={true}
                  control={control}
                  render={(f) => {
                    return <Input type="text" maxW="400px" {...f} />;
                  }}
                />
                <GroupController
                  name={us_domestic_account_number}
                  label={t(
                    `steps.${stepId}.${us_domestic_account_number}.label`,
                  )}
                  helper={t(
                    `steps.${stepId}.${us_domestic_account_number}.helper`,
                  )}
                  isRequired={true}
                  control={control}
                  render={(f) => {
                    return <Input type="text" maxW="400px" {...f} />;
                  }}
                />
                <GroupController
                  name={us_domestic_reference_field}
                  label={t(
                    `steps.${stepId}.${us_domestic_reference_field}.label`,
                  )}
                  control={control}
                  render={(f) => {
                    return <Input type="text" maxW="400px" {...f} />;
                  }}
                />
                <GroupController
                  name={us_international_account_name}
                  label={t(
                    `steps.${stepId}.${us_international_account_name}.label`,
                  )}
                  helper={t(
                    `steps.${stepId}.${us_international_account_name}.helper`,
                  )}
                  isRequired={true}
                  control={control}
                  render={(f) => {
                    return <Input type="text" maxW="400px" {...f} />;
                  }}
                />
                <GroupController
                  name={us_internationa_swift_bic}
                  label={t(
                    `steps.${stepId}.${us_internationa_swift_bic}.label`,
                  )}
                  helper={t(
                    `steps.${stepId}.${us_internationa_swift_bic}.helper`,
                  )}
                  isRequired={true}
                  control={control}
                  render={(f) => {
                    return <Input type="text" maxW="400px" {...f} />;
                  }}
                />
                <GroupController
                  name={us_international_account_number}
                  label={t(
                    `steps.${stepId}.${us_international_account_number}.label`,
                  )}
                  helper={t(
                    `steps.${stepId}.${us_international_account_number}.helper`,
                  )}
                  isRequired={true}
                  control={control}
                  render={(f) => {
                    return <Input type="text" maxW="400px" {...f} />;
                  }}
                />
                <GroupController
                  name={us_intenational_reference_field}
                  label={t(
                    `steps.${stepId}.${us_intenational_reference_field}.label`,
                  )}
                  control={control}
                  render={(f) => {
                    return <Input type="text" maxW="400px" {...f} />;
                  }}
                />
              </>
            )}
          </>
        )}

        <Box>
          <Button
            variant="next"
            isLoading={isSubmitting}
            isDisabled={!isValid}
            type="submit"
          >
            {t('domain.form.next')}
          </Button>
        </Box>
      </VStack>
    </form>
  );
};
