import { useMemo } from 'react';
import { omit } from 'lodash';
import { Box, Button, Input, VStack } from '@chakra-ui/react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Checkbox,
  CountrySelect,
  GroupController,
  Radio,
} from 'frontend-components';
import { useStepId, useStore, useSubmitCustomStepForm } from 'frontend-common';

const current_company = 'What is the name of your current employer/Company?';
const position = 'What is your position title?';
const designated_signatory = 'designated_signatory';
const us_citizen = 'individual_is_us_citizen_or_taxpayer';
const taxpayer_identification_number_tin = 'taxpayer_identification_number_tin';
const is_pep = 'individual_is_politically_exposed';
const pep_relation = 'What is your relation to a PEP?';
const pep_relation_options = [
  'You are/was a PEP',
  'A relative of yours is/was a PEP',
  'A close associate of yours is/was a PEP',
];
const active_position = 'active_pep_individual';
const pep_countries = 'country_associated_with_the_pep_activities';
const describe_government =
  'Please describe the official government responsibilities';
const level_authority =
  'level_and_nature_of_peps_authority_or_influence_over_government_activities_or_officials';
const access_assets =
  'pep_have_access_to_significant_government_assets_or_funds';

const validationSchema = Yup.object({
  [current_company]: Yup.string().required(),
  [position]: Yup.string().required(),
  [designated_signatory]: Yup.string().oneOf(['true', 'false']).required(),
  [us_citizen]: Yup.string().oneOf(['true', 'false']).required(),
  [taxpayer_identification_number_tin]: Yup.string().when(us_citizen, {
    is: (val: string) => val === 'true',
    then: (schema) => schema.required(),
    otherwise: (schema) =>
      schema
        .optional()
        .nullable()
        .default(null)
        .transform(() => null),
  }),
  [is_pep]: Yup.string().oneOf(['true', 'false']).required(),
  [pep_relation]: Yup.array(Yup.mixed().oneOf(pep_relation_options)).when(
    is_pep,
    {
      is: (val: string) => val === 'true',
      then: (schema) => schema.min(1).required(),
      otherwise: (schema) =>
        schema
          .optional()
          .nullable()
          .default(null)
          .transform(() => null),
    },
  ),
  [active_position]: Yup.string()
    .oneOf(['true', 'false'])
    .when(is_pep, {
      is: (val: string) => val === 'true',
      then: (schema) => schema.required(),
      otherwise: (schema) =>
        schema
          .optional()
          .nullable()
          .default(null)
          .transform(() => null),
    }),
  [pep_countries]: Yup.array().when(is_pep, {
    is: (val: string) => val === 'true',
    then: (schema) => schema.min(1).required(),
    otherwise: (schema) =>
      schema
        .optional()
        .nullable()
        .default(null)
        .transform(() => null),
  }),
  [describe_government]: Yup.string().when(is_pep, {
    is: (val: string) => val === 'true',
    then: (schema) => schema.required(),
    otherwise: (schema) =>
      schema
        .optional()
        .nullable()
        .default(null)
        .transform(() => null),
  }),
  [level_authority]: Yup.string().when(is_pep, {
    is: (val: string) => val === 'true',
    then: (schema) => schema.required(),
    otherwise: (schema) =>
      schema
        .optional()
        .nullable()
        .default(null)
        .transform(() => null),
  }),
  [access_assets]: Yup.string()
    .oneOf(['true', 'false'])
    .when(is_pep, {
      is: (val: string) => val === 'true',
      then: (schema) => schema.required(),
      otherwise: (schema) =>
        schema
          .optional()
          .nullable()
          .default(null)
          .transform(() => null),
    }),
});

export const AdditionalDetails = () => {
  const stepId = useStepId();

  const { t } = useTranslation();
  const { submitCustomStepForm } = useSubmitCustomStepForm();
  const { metadata, individuals } = useStore();

  const default_designated_signatory =
    individuals[0]?.custom_properties?.[designated_signatory] || '';
  const default_us_citizen =
    individuals[0]?.custom_properties?.[us_citizen] || '';
  const default_taxpayer_identification_number_tin =
    individuals[0]?.custom_properties?.[taxpayer_identification_number_tin] ||
    '';
  const default_is_pep = individuals[0]?.custom_properties?.[is_pep] || '';
  const default_active_position =
    individuals[0]?.custom_properties?.[active_position] || '';
  const default_pep_countries =
    individuals[0]?.custom_properties?.[pep_countries] || '';
  const default_level_authority =
    individuals[0]?.custom_properties?.[level_authority] || '';
  const default_access_assets =
    individuals[0]?.custom_properties?.[access_assets] || '';
  const defaultValues = useMemo(() => {
    return {
      [current_company]: metadata?.[current_company] || '',
      [position]: metadata?.[position] || '',
      [designated_signatory]: default_designated_signatory,
      [us_citizen]: default_us_citizen,
      [taxpayer_identification_number_tin]:
        default_taxpayer_identification_number_tin,
      [is_pep]: default_is_pep || '',
      [pep_relation]: metadata?.[pep_relation] || '',
      [active_position]: default_active_position || '',
      [pep_countries]: default_pep_countries || '',
      [describe_government]: metadata?.[describe_government] || '',
      [level_authority]: default_level_authority || '',
      [access_assets]: default_access_assets || '',
    };
  }, [
    metadata,
    default_designated_signatory,
    default_us_citizen,
    default_taxpayer_identification_number_tin,
    default_is_pep,
    default_active_position,
    default_pep_countries,
    default_level_authority,
    default_access_assets,
  ]);

  const methods = useForm<any>({
    mode: 'all',
    criteriaMode: 'all',
    // @TODO - OPS-9 - Replace Yup by Zod
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    control,
    setValue,
    watch,
    resetField,
    formState: { isValid, isSubmitting },
  } = methods;

  const onSubmit: SubmitHandler<any> = async (formData) => {
    const metadata = omit(
      formData,
      [designated_signatory],
      [us_citizen],
      [taxpayer_identification_number_tin],
      [is_pep],
      [active_position],
      [pep_countries],
      [level_authority],
      [access_assets],
    );
    submitCustomStepForm({
      caseMetadata: metadata,
      individualId: individuals[0].id,
      individualData: {
        ...individuals[0],
        custom_properties: {
          ...individuals[0]?.custom_properties,
          [designated_signatory]: formData[designated_signatory],
          [us_citizen]: formData[us_citizen],
          [taxpayer_identification_number_tin]:
            formData[taxpayer_identification_number_tin],
          [is_pep]: formData[is_pep],
          [active_position]: formData[active_position],
          [pep_countries]: formData[pep_countries],
          [level_authority]: formData[level_authority],
          [access_assets]: formData[access_assets],
        },
      },
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <VStack spacing="6" alignItems="start">
        <GroupController
          name={designated_signatory}
          label={t(`steps.${stepId}.${designated_signatory}.label`)}
          isRequired={true}
          control={control}
          render={(f) => (
            <Radio
              stepId={stepId}
              name={designated_signatory}
              onChange={(value: string) => {
                setValue(designated_signatory, value ?? '', {
                  shouldDirty: true,
                  shouldValidate: true,
                });
              }}
              options={['true', 'false']}
              defaultValue={f.value}
            />
          )}
        />

        <GroupController
          name={current_company}
          label={t(`steps.${stepId}.${current_company}.label`)}
          isRequired={true}
          control={control}
          render={(f) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-igfalsere
            return <Input type="text" maxW="400px" {...f} />;
          }}
        />

        <GroupController
          name={position}
          label={t(`steps.${stepId}.${position}.label`)}
          isRequired={true}
          control={control}
          render={(f) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-igfalsere
            return <Input type="text" maxW="400px" {...f} />;
          }}
        />

        <GroupController
          name={us_citizen}
          label={t(`steps.${stepId}.${us_citizen}.label`)}
          isRequired={true}
          control={control}
          render={(f) => (
            <Radio
              stepId={stepId}
              name={us_citizen}
              onChange={(value: string) => {
                resetField(taxpayer_identification_number_tin);
                setValue(us_citizen, value ?? '', {
                  shouldDirty: true,
                  shouldValidate: true,
                });
              }}
              options={['true', 'false']}
              defaultValue={f.value}
            />
          )}
        />

        {watch(us_citizen) === 'true' && (
          <GroupController
            name={taxpayer_identification_number_tin}
            isRequired={true}
            label={t(
              `steps.${stepId}.${taxpayer_identification_number_tin}.label`,
            )}
            control={control}
            render={(f) => <Input type="text" maxW="400px" {...f} />}
          />
        )}

        <GroupController
          name={is_pep}
          label={t(`steps.${stepId}.${is_pep}.label`)}
          isRequired={true}
          control={control}
          render={(f) => (
            <Radio
              stepId={stepId}
              name={is_pep}
              onChange={(value: string) => {
                setValue(is_pep, value ?? '', {
                  shouldDirty: true,
                  shouldValidate: true,
                });
              }}
              options={['true', 'false']}
              defaultValue={f.value}
            />
          )}
        />

        {watch(is_pep) === 'true' && (
          <>
            <GroupController
              name={pep_relation}
              label={t(`steps.${stepId}.${pep_relation}.label`)}
              control={control}
              render={(f) => (
                <Checkbox
                  stepId={stepId}
                  name={pep_relation}
                  onChange={(values: string[]) => {
                    setValue(pep_relation, values ?? [], {
                      shouldDirty: true,
                      shouldValidate: true,
                    });
                  }}
                  options={pep_relation_options}
                  defaultValue={f.value}
                />
              )}
            />
            <GroupController
              name={active_position}
              label={t(`steps.${stepId}.${active_position}.label`)}
              isRequired={true}
              control={control}
              render={(f) => (
                <Radio
                  stepId={stepId}
                  name={active_position}
                  onChange={(value: string) => {
                    setValue(active_position, value ?? '', {
                      shouldDirty: true,
                      shouldValidate: true,
                    });
                  }}
                  options={['true', 'false']}
                  defaultValue={f.value}
                />
              )}
            />
            <GroupController
              name={pep_countries}
              label={t(`steps.${stepId}.${pep_countries}.label`)}
              isRequired={true}
              control={control}
              render={(f) => (
                <CountrySelect
                  onChange={(value: string[]) => {
                    setValue(pep_countries, value ?? [], {
                      shouldDirty: true,
                      shouldValidate: true,
                    });
                  }}
                  defaultValue={f.value}
                  isMulti={true}
                />
              )}
            />
            <GroupController
              name={describe_government}
              label={t(`steps.${stepId}.${describe_government}.label`)}
              isRequired={true}
              control={control}
              render={(f) => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-igfalsere
                return <Input type="text" maxW="400px" {...f} />;
              }}
            />
            <GroupController
              name={level_authority}
              label={t(`steps.${stepId}.${level_authority}.label`)}
              isRequired={true}
              control={control}
              render={(f) => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-igfalsere
                return <Input type="text" maxW="400px" {...f} />;
              }}
            />
            <GroupController
              name={access_assets}
              label={t(`steps.${stepId}.${access_assets}.label`)}
              isRequired={true}
              control={control}
              render={(f) => (
                <Radio
                  stepId={stepId}
                  name={access_assets}
                  onChange={(value: string) => {
                    setValue(access_assets, value ?? '', {
                      shouldDirty: true,
                      shouldValidate: true,
                    });
                  }}
                  options={['true', 'false']}
                  defaultValue={f.value}
                />
              )}
            />
          </>
        )}

        <Box>
          <Button
            variant="next"
            isLoading={isSubmitting}
            isDisabled={!isValid}
            type="submit"
          >
            {t('domain.form.next')}
          </Button>
        </Box>
      </VStack>
    </form>
  );
};
