import { useMemo } from 'react';
import {
  Box,
  Button,
  Input,
  VStack,
  Textarea,
  InputGroup,
  InputLeftAddon,
} from '@chakra-ui/react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { GroupController, Select, Radio } from 'frontend-components';
import { useStepId, useStore, useSubmitCustomStepForm } from 'frontend-common';

const source_of_wealth = 'Source of wealth';
const source_of_wealth_options = [
  'Incomes',
  'Treasury',
  'ICO/IEO',
  'Fundraising',
  'Sale of an investment',
  'Dividends',
  'Company Sale',
  'Trading Profits',
  'Mining',
  'Staking',
  'Lending/Liquidity pools',
  'Airdrops',
  'Lottery/Betting/Casino',
];
const source_of_funds = 'Source of funds';
const source_of_funds_options = [
  'Incomes',
  'Treasury',
  'ICO/IEO',
  'Fundraising',
  'Sale of an investment',
  'Dividends',
  'Company Sale',
  'Trading Profits',
  'Mining',
  'Staking',
  'Lending/Liquidity pools',
  'Airdrops',
  'Lottery/Betting/Casino',
];
const source_of_funds_wallet_address = 'Source of funds - wallet addresses';
const otc_transactions =
  'Will you be engaging in Over-the-Counter (OTC) transactions with Flowdesk?';
const maximun_trading_volume = 'Maximum trading volume ($)';
const detail_of_the_currencies = 'Detail of the currencies';

const validationSchema = Yup.object({
  [source_of_wealth]: Yup.string()
    .oneOf([...source_of_wealth_options, 'other'])
    .required(),
  [`${source_of_wealth}_other`]: Yup.string().when(source_of_wealth, {
    is: (val: string) => val === 'other',
    then: (schema) => schema.required(),
    otherwise: (schema) =>
      schema
        .optional()
        .nullable()
        .default(null)
        .transform(() => null),
  }),
  [source_of_funds]: Yup.string()
    .oneOf([...source_of_funds_options, 'other'])
    .required(),
  [`${source_of_funds}_other`]: Yup.string().when(source_of_funds, {
    is: (val: string) => val === 'other',
    then: (schema) => schema.required(),
    otherwise: (schema) =>
      schema
        .optional()
        .nullable()
        .default(null)
        .transform(() => null),
  }),
  [source_of_funds_wallet_address]: Yup.string().required(),
  [otc_transactions]: Yup.string().oneOf(['Yes', 'No']).required(),
  [maximun_trading_volume]: Yup.number()
    .transform((v) => (isNaN(v) || v === '' ? null : v))
    .when(otc_transactions, {
      is: (val: string) => val === 'Yes',
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.optional().nullable().default(null),
    }),
  [detail_of_the_currencies]: Yup.string().when(otc_transactions, {
    is: (val: string) => val === 'Yes',
    then: (schema) => schema.required(),
    otherwise: (schema) =>
      schema
        .optional()
        .nullable()
        .default(null)
        .transform(() => null),
  }),
});

export const SourceOfFunds = () => {
  const stepId = useStepId();

  const { t } = useTranslation();
  const { submitCustomStepForm } = useSubmitCustomStepForm();
  const { metadata } = useStore();

  const defaultValues = useMemo(() => {
    return {
      [source_of_wealth]: metadata?.[source_of_wealth] || '',
      [`${source_of_wealth}_other`]:
        metadata?.[`${source_of_wealth}_other`] || '',
      [source_of_funds]: metadata?.[source_of_funds] || '',
      [`${source_of_funds}_other`]:
        metadata?.[`${source_of_funds}_other`] || '',
      [source_of_funds_wallet_address]:
        metadata?.[source_of_funds_wallet_address] || '',
      [otc_transactions]: metadata?.[otc_transactions] || '',
      [maximun_trading_volume]: metadata?.[maximun_trading_volume] || null,
      [detail_of_the_currencies]: metadata?.[detail_of_the_currencies] || '',
    };
  }, [metadata]);

  const methods = useForm<any>({
    mode: 'all',
    criteriaMode: 'all',
    // @TODO - OPS-9 - Replace Yup by Zod
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    control,
    setValue,
    watch,
    resetField,
    formState: { isValid, isSubmitting },
  } = methods;

  const onSubmit: SubmitHandler<any> = async (formData) => {
    submitCustomStepForm({ caseMetadata: formData });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <VStack spacing="6" alignItems="start">
        <Box w="100%">
          <GroupController
            name={source_of_wealth}
            label={t(`steps.${stepId}.${source_of_wealth}.label`)}
            helper={t(`steps.${stepId}.${source_of_wealth}.helper`)}
            isRequired={true}
            control={control}
            render={(f) => (
              <Select
                stepId={stepId}
                name={source_of_wealth}
                onChange={(value: string) => {
                  setValue(source_of_wealth, value ?? '', {
                    shouldDirty: true,
                    shouldValidate: true,
                  });
                }}
                options={source_of_wealth_options}
                defaultValue={f.value}
                hasOtherOption={true}
              />
            )}
          />
          {watch(source_of_wealth) &&
            watch(source_of_wealth).includes('other') && (
              <GroupController
                mt={5}
                name={`${source_of_wealth}_other`}
                label={t(
                  `steps.${stepId}.${source_of_wealth}.other_option.label`,
                )}
                isRequired={true}
                control={control}
                render={(f) => {
                  return <Input type="text" maxW="400px" {...f} />;
                }}
              />
            )}
        </Box>

        <Box w="100%">
          <GroupController
            name={source_of_funds}
            label={t(`steps.${stepId}.${source_of_funds}.label`)}
            helper={t(`steps.${stepId}.${source_of_funds}.helper`)}
            isRequired={true}
            control={control}
            render={(f) => (
              <Select
                stepId={stepId}
                name={source_of_funds}
                onChange={(value: string) => {
                  setValue(source_of_funds, value ?? '', {
                    shouldDirty: true,
                    shouldValidate: true,
                  });
                }}
                options={source_of_funds_options}
                defaultValue={f.value}
                hasOtherOption={true}
              />
            )}
          />
          {watch(source_of_funds) &&
            watch(source_of_funds).includes('other') && (
              <GroupController
                mt={5}
                name={`${source_of_funds}_other`}
                label={t(
                  `steps.${stepId}.${source_of_funds}.other_option.label`,
                )}
                isRequired={true}
                control={control}
                render={(f) => {
                  return <Input type="text" maxW="400px" {...f} />;
                }}
              />
            )}
        </Box>

        <GroupController
          name={source_of_funds_wallet_address}
          label={t(`steps.${stepId}.${source_of_funds_wallet_address}.label`)}
          helper={t(`steps.${stepId}.${source_of_funds_wallet_address}.helper`)}
          isRequired={true}
          control={control}
          render={(f) => {
            return <Input type="text" maxW="400px" {...f} />;
          }}
        />

        <GroupController
          name={otc_transactions}
          label={t(`steps.${stepId}.${otc_transactions}.label`)}
          isRequired={true}
          control={control}
          render={(f) => (
            <Radio
              stepId={stepId}
              name={otc_transactions}
              onChange={(value: string) => {
                resetField(maximun_trading_volume);
                resetField(detail_of_the_currencies);
                setValue(otc_transactions, value ?? '', {
                  shouldDirty: true,
                  shouldValidate: true,
                });
              }}
              options={['Yes', 'No']}
              defaultValue={f.value}
            />
          )}
        />

        {watch(otc_transactions) === 'Yes' && (
          <>
            <GroupController
              name={maximun_trading_volume}
              label={t(`steps.${stepId}.${maximun_trading_volume}.label`)}
              helper={t(`steps.${stepId}.${maximun_trading_volume}.helper`)}
              isRequired={true}
              control={control}
              render={(f) => {
                return (
                  <InputGroup maxW="400px">
                    <InputLeftAddon children="$" />
                    <Input type="number" {...f} />
                  </InputGroup>
                );
              }}
            />
            <GroupController
              name={detail_of_the_currencies}
              label={t(`steps.${stepId}.${detail_of_the_currencies}.label`)}
              helper={t(`steps.${stepId}.${detail_of_the_currencies}.helper`)}
              isRequired={true}
              control={control}
              render={(f) => {
                return <Textarea maxW="400px" resize="vertical" {...f} />;
              }}
            />
          </>
        )}

        <Box>
          <Button
            variant="next"
            isLoading={isSubmitting}
            isDisabled={!isValid}
            type="submit"
          >
            {t('domain.form.next')}
          </Button>
        </Box>
      </VStack>
    </form>
  );
};
