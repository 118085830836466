const brandColors = {
  primary: '#000000',
  secondary: '#5c2bd9',
};

const sidebarColors = {
  backgroundColor: '#000000',
  color: '#ffffff',
  dotfileLogo: '#ffffff',
};

const linkColors = {
  color: '#5c2bd9',
};

export const tokens = {
  brand: brandColors,
  sidebar: sidebarColors,
  link: linkColors,
};
