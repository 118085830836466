import { BaseStepIdEnum, StepConfig, StepTypeEnum } from 'shared-domain';
import { NominatedBankingAccount } from '../app/components/nominated-banking-account';
import { AdditionalDetails } from '../app/components/additional-details';
import { SourceOfFunds } from '../app/components/source-of-funds';

export const stepsConfig: StepConfig[] = [
  {
    id: 'disclaimer',
    type: StepTypeEnum.text,
  },
  {
    id: BaseStepIdEnum.individual_edit,
  },
  {
    id: 'additional_details',
    type: StepTypeEnum.custom_component,
    component: AdditionalDetails,
  },
  {
    id: 'source_of_funds',
    type: StepTypeEnum.custom_component,
    component: SourceOfFunds,
  },
  {
    id: 'nominated_banking_account',
    type: StepTypeEnum.custom_component,
    component: NominatedBankingAccount,
  },
  {
    id: 'final_confirmation',
    type: StepTypeEnum.text,
  },
];
